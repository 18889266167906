html {
  
  overflow: hidden;
}

body {
  overflow: hidden;
  margin: 0;
  padding: 0;
  width:100%;
  height:100%;
  background-color: black;
  color:white;
  font-family: sans-serif;
  overscroll-behavior-y: contain;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
}

.container {
  position: fixed;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  position: absolute;
  top:0;
  bottom: 0;
  left:0;
  right:0;
  overflow:'hidden';
}

.nonselectable {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

